@import "./mixins";
@import "./variables";


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: $main;
}

::selection {
  background: rgba(0, 109, 192, 0.50);
}

.secondary-title {
    @include font($dark, 64px, 700, normal, 0);
}

.body-hidden {
    max-height: 100vh;
    overflow: hidden;
}


// Adaptive 
@media screen and (max-height: 600px) {
  .body-hidden {
    height: 100vh;
    overflow: hidden;
  }
}

@media screen and (max-width: 2000px) {
  .secondary-title {
    font-size: 50px;
  } 
}

@media screen and (max-width: 2000px) and (max-height: 850px) {
  .secondary-title {
    font-size: 50px;
  } 
}

@media screen and (max-width: 1700px) {
  .secondary-title {
    font-size: 48px;
  } 
}

@media screen and (max-width: 1700px) and (max-height: 750px) {
  .secondary-title {
    font-size: 44px;
  } 
}

@media screen and (max-width: 1200px) {
    .secondary-title {
        font-size: 32px;
    }
}
