@import "../../main/style.scss";


.statistic {
	width: 100%;
	height: auto;
	background-image: url(../img/benefit__bg.jpg);
	background-position: center;
	background-size: cover;
	padding: 100px;


	&__title {
		margin-bottom: 80px;
		text-align: center;
		color: $white !important;
		transform: translateY(100px);
		opacity: 0;

		&.animate {
			transition: .3s;
			transform: translateY(0px);
			opacity: 1;
		}
	}



	&__wrapper {
		@include display-center(center, start);
		column-gap: 180px;
		row-gap: 120px;
		flex-wrap: wrap;

		.statistic__card {
			height: auto;
			@include display-center(center, center);
			flex-direction: column;
			gap: 25px;
			position: relative;
			bottom: 20px;

			opacity: 0;
			&:nth-child(1) {
				transform: translateX(-40px);
			}
			&:nth-child(2) {
				transform: translateX(40px);
			}
			&:nth-child(3) {
				transform: translateX(-40px);
			}
			&:nth-child(4) {
				transform: translateX(40px);
			}


			&.animate {
				transition: .5s;
				opacity: 1;

				&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
					transform: translateX(0px);
				}
			}
			

			&-number {
				padding: 15px 50px;
				background-color: $main;
				@include font($white, 32px, 700, normal, 0);
				@include display-center(center, center);
			}

			&-description {
				width: 600px;
				height: auto;
				@include font($white, 32px, 700, normal, 0);
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: 2000px) {
	.statistic {
		padding: 100px 160px;
		column-gap: 100px;
		justify-content: space-between;
		&__wrapper {
			.statistic__card {
				&-description {
					width: 360px;
					height: auto;
					@include font($white, 26px, 700, normal, 0);
					text-align: center;
				}
			}
		}
	}
}

@media screen and (max-width: 1700px) {
	.statistic {
		padding: 120px;
		column-gap: 60px; 
		justify-content: center;


		&__wrapper {
			.statistic__card {
				&-number {
					padding: 10px 50px;
				}
		
				&-description {
					font-size: 24px;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.statistic {
		padding: 120px 60px;

		&__wrapper {
			.statistic__card {
				&-number {
					font-size: 28px;
				}
	
				&-description {
					width: 420px;
					font-size: 24px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.statistic {
		padding: 120px 30px;
		row-gap: 80px;
		flex-direction: column;

		&__wrapper {
			.statistic__card {
				gap: 20px;
				// &-title {
				// 	@include font($white, 28px, 700, normal, 0);
				// }
		
				&-number {
					padding: 10px 40px;
					@include font($white, 24px, 700, normal, 0);
				}
		
				&-description {
					@include font($white, 18px, 700, normal, 0);
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	.statistic {
		&__wrapper {
			.statistic__card {
				&-description {
					width: 100%;
				}
			}
		}
	}
}