@import "./../../main/style.scss";


.service__wrapper {
    background-image: url(../img/background-line.svg);
    background-repeat: no-repeat;
    background-position: center;
    padding: 140px 0;

    .service {
        padding: 0 120px;

        &__title {
            text-align: center;
            margin-bottom: 50px;
            transform: translateY(100px);
            opacity: 0;

            &.animate {
                transition: .3s;
                opacity: 1;
                transform: translateY(0px);
            }
        }   

        &__row {
            max-width: 1600px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;


            column-gap: 50px;
            row-gap: 50px; 

            .service__card {
                transform: translateY(100px);
                opacity: 0;

                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }
}


@media screen and (max-width: 1700px) {

}


@media screen and (max-width: 1200px) {
    .service__wrapper {
        .service {
            padding: 0 60px;

            &__title {
                margin-bottom: 30px;
            }   
    
            &__row {
                column-gap: 24px;
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .service__wrapper {
        .service {
            padding: 0 30px;

            &__row {
                column-gap: 0px;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .service__wrapper {
        .service {
            padding: 0 30px;

            &__row {
                column-gap: 0px;
                row-gap: 25px;
            }
        }
    }
}