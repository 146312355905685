@import "../../main/style.scss";

.work {
	width: 100%;
	height: auto;
	background-image: url(../img/work__bg.jpg);
	background-size: cover;
	padding: 0 120px;
	@include display-center(flex-end, center);
	flex-direction: column;
	

	&__title {
		color: $white !important;
		margin: 120px 0 80px;
		z-index: 1;
		text-align: center;
		transform: translateY(100px);
		opacity: 0;

		&.animate {
			transition: .3s;
			opacity: 1;
			transform: translateY(0px);
		}
	}

	&__box {
		width: 100%;
		height: 800px; 
		position: relative;
		@include display-center(space-between, center);
		flex-direction: column;
		
		&-container {
			width: 100%;
			height: 33.333%;

			&1, &2, &3 {
				.work__text {
					transform: translateX(-100px);
				}
			}

			&.animate {
				.work__text {
					transition: 0.5s;
					opacity: 1;
					transform: translateX(0px);
				}
			}


			.work__text {
				width: 49%;
				height: 40px;
				padding-left: 75px;
				opacity: 0;

				&:nth-child(2) {
					transform: translateX(100px);
				}

				&.animate {
					transition: 0.5s;
					opacity: 1;
					transform: translateX(0px);
				}



				&-title {
					@include font($white, 36px, 700, normal, 0);
				}

				&-description {
					@include font($white, 24px, 500, normal, 0); 
					margin-top: 15px;
				}

			}

		}

		&-container1 {
			@include display-center(flex-end, flex-start);
		}

		&-container2 {
			@include display-center(flex-start, flex-start);
			.work__text {
				width: 49%;
				height: 40px;
				padding-left: 0px;
				padding-right: 75px;
			}
		}


		&-number {
			width: 100%;
			height: 800px; 
			position: absolute;
			bottom: 0px;
		}

		&-element {
			width: 100%;
			height: 33.333%;
			@include display-center(center, flex-start);
		}

		&-round {
			width: 110px;
			height: 110px;
			background-color: $main;
			border-radius: 50%;
			@include display-center(center, center);
			@include font($white, 44px, 700, normal, 0);
			z-index: 1;
		}


		// rigth
		&::after {
			content: "";
			width: 3px;
			height: 100%;
			background-color: rgba(0, 108, 192, 0.80);
			position: absolute;
			bottom: 0px;
			z-index: 0;
		}
	}
}


@media screen and (max-width: 2000px) {
	.work {
		&__box {
			&-round {
				width: 100px;
				height: 100px;
				font-size: 40px;
			}
		}
	}
}


@media screen and (max-width: 1700px) {
	.work {
		width: 100%;
		height: auto;
		background-size: cover;
		padding: 0 120px;
		@include display-center(flex-end, center);
		flex-direction: column;

		&__title {
			margin: 80px 0;
		}
		
		&__box {
			height: 700px;
			
			&-container {
				.work__text {
					width: 50%;
	
					&-title {
						font-size: 32px;
					}
	
					&-description {
						font-size: 20px;
					}
				}
			}
	
			&-number {
				height: 700px;
			}
	
			&-round {
				width: 80px;
				height: 80px;
				font-size: 36px;
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.work {
		padding: 0px 60px;
	}
}

@media screen and (max-width: 1000px) {
	.work {
		&__title {
			margin: 60px 0;
		}
	
		&__box {
			width: 100%;
			height: 1000px;
			position: relative;
			@include display-center(space-between, center);
			flex-direction: column;
			
			&-container {
				width: 100%;
				height: 33.333%;
				padding-top: 100px;

				&1, &3 {
					.work__text {
						transform: translateX(-100px);
					}
				}

				&2 {
					.work__text {
						transform: translateX(100px);
					}
				}

				&.animate {
					.work__text {
						transition: 0.5s;
						opacity: 1;
						transform: translateX(0px);
					}
				}

				.work__text {
					width: 100%;
					height: 40px;
					padding-left: 0px;
					flex-direction: column;
					@include display-center(space-between, center);
					opacity: 0;



					&-title {
						@include font($white, 28px, 700, normal, 0);
						text-align: center;
					}
	
					&-description {
						@include font($white, 20px, 500, normal, 0);
						margin-top: 5px;
						text-align: center;
					}
	
				}
	
			}
	
			&-container2 {
				@include display-center(flex-start, flex-start);
				.work__text {
					width: 100%;
					height: 40px;
					padding-left: 0px;
					padding-right: 0px;
					text-align: center;
				}
			}
	
			&-number {
				width: 100%;
				height: 1000px;
			}
			&-round {
				@include font($white, 32px, 700, normal, 0);
			}

			&::after {
				display: none;
			}
	
		}

	}
}

@media screen and (max-width: 800px) {
	.work {
		padding: 0px 30px;
		background-position: 20%;
	}
}


@media screen and (max-width: 500px) {
	.work {
		&__title {
			font-size: 28px !important;
		}
		&__box {
			width: 100%;
			height: 1100px;
			position: relative;
			@include display-center(space-between, center);
			flex-direction: column;
			
			&-container {
				.work__text {

					&-title {
						@include font($white, 24px, 700, normal, 0);
					}
	
					&-description {
						@include font($white, 16px, 500, normal, 0);
					}
	
				}
	
			}
	
			&-number {
				height: 1100px;
			}
			&-round {
				width: 70px;
				height: 70px;
				@include font($white, 28px, 700, normal, 0);
			}
	
		}

	}
}