@import "./../../main/style.scss";


.footer__wrapper {
	position: relative;
	background-color: $dark;

    .footer {
		padding: 360px 180px 0; // 280 120 0

        &__row {
            padding-bottom: 100px;
            display: flex;
            justify-content: space-between; 

            .footer__info {
				transform: translateY(100px);
                opacity: 0;

                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }

                .footer__logo {
					& img {
						margin-bottom: 25px;
						width: 200px;
					}
                }

                &-description {
                    max-width: 500px;
                    @include font($white, 22px, 400, normal, 0);
                }
            }

            .footer__list {
                row-gap: 15px;
				transform: translateY(100px);
                opacity: 0;

                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }

                .footer__title {
                    margin-bottom: 25px;
                    @include font($white, 30px, 600, normal, 0); // 32
                }	


                .footer__item {
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
					cursor: pointer;

					& svg  {
						width: 32px;
						height: 32px;
						transition: .5s;

						& path, & circle  { transition: .5s; }
					}

					.footer__item-company {
						transition: .5s;
						& path { transition: .5s; }
					}

                    &:hover  {
						transition: .5s;

						& svg {
							transition: .5s;

							& path, & circle {
								transition: .5s;
								stroke: $main;
								background-color: $main;
							}
						}

						.footer__item-company {
							transition: .5s;

							& path {
								transition: .5s;
								fill: $main;
								stroke: none;
								background-color: $main;
							}
						}



						.footer__link{
							transition: .5s;
                            color: $main;
						} 
                    }


                    .footer__link {
                        transition: .5s;
                        @include font($white, 22px, 500, normal, 0); // 20
						white-space: pre-line;
                    }
                }

                .footer__item:last-child {
                    margin-bottom: 0px;
                }

                .footer__social-media {
                    margin-top: 40px; // 20
                    display: flex;
                    column-gap: 15px;
                }
            }
        }

        &__description {
            padding-bottom: 80px;
            text-align: center;
            @include font($white, 24px, 400, normal, 150%); // 24
            border-bottom: 4px solid $main;
			opacity: 0;
			transform: translateX(100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: 1;
			}
        }

        &__copyright {
            padding: 40px 0;  // 25 0
            text-align: center;
			@include display-center(space-between, center);
			opacity: 0;
			transform: translateX(-100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: 1;
			}
			@include font($white, 20px, 400, normal, 150%); // 20

			&-text {
				font-size: 20px;
				opacity: 0.8;
			}

			&-producer {
				display: flex;
				column-gap: 10px;
				align-items: center;
				font-size: 16px;
				color: $white;
				opacity: 0.8;
				transition: .4s;


				&:hover {
					opacity: 0.6;
					transition: .4s;

					& svg {
						transform: rotate(360deg);
						transition: .6s;
					}
				}
			}
        }
    }



	.footer__card {
		padding: 0 180px;
		width: 100%;

		position: absolute;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		grid-template-columns: repeat(4, 1fr);
		column-gap: 30px;
		row-gap: 40px;

		&-card {
			width: 360px;
			height: 450px;
			border-radius: 5px;
			background: #FFF;
			box-shadow: 0px 0px 4.5px 0px rgba(0, 108, 192, 0.65);
			@include display-center(space-between, center);
			flex-direction: column;
			padding: 25px; // 15
			transform: translateY(100px);
			opacity: 0;

			&--last {
				@include display-center(flex-start, center);
			}

			&.animate {
				transition: .3s;
				opacity: 1;
				transform: translateY(0px);
			}


			.footer__card-img {
				min-width: 90px;
				min-height: 90px;
			}

			.footer__card-name {
				@include font($dark, 28px, 500, normal, 0); // 20
				margin-top: 10px;
				text-align: center;
			}

			.footer__card-position {
				@include font($dark, 24px, 400, normal, 0); // 20
				text-align: center;
			}


			.footer__card-languages {
				margin-top: 5px;
				@include display-center(center, center);
				column-gap: 25px;

				& svg {
					border-radius: 2px;
					width: 34px;
					height: 24px;
				}
			}


			.footer__card-container {
				margin-top: 10px;
				@include display-center(flex-start, center);
				flex-direction: column;

				&--info {
					margin-top: 25px;
				}

				.footer__card-box__description {
					margin-bottom: 10px;
					text-align: center;
					@include font($dark, 28px, 500, normal, 0); //

					&--info {
						margin-top: 35px;
					}
				}
			}

			.footer__card-box {
				@include display-center(flex-start, center);
				cursor: pointer;
				margin-bottom: 10px;	
				gap: 10px;
				transition: .5s;

				.footer__card-img {
					min-width: 30px; // 24
					min-height: 30px; // 24
					transition: .5s;

					& path {
						transition: .5s;
					}
				}

				&:hover {
					transition: .5s;

					.footer__card-img {
						transition: .5s;

						& path {
							transition: .5s;
							stroke: $dark;
						}
					}

					.footer__card-text {
						transition: .5s;
						color: $dark;
					}
				}

				.footer__card-text {
					@include font($main, 24px, 500, normal, 0);  // 18
					cursor: pointer;
					transition: .5s;
				}
			}

			.footer__sociable {
				margin-top: 15px;

				display: flex;
				column-gap: 15px;
			}
		}
	}
}


@media screen and (max-width: 2000px) {
	.footer__wrapper {
		.footer {
			padding: 320px 160px 0;

			&__row {
				.footer__info {
					.footer__logo {
						& img {
							margin-bottom: 20px;
						}
					}
	
					&-description {
						max-width: 310px;
						font-size: 20px;
					}
				}


				.footer__list {
					row-gap: 15px;
	
					.footer__title {
						font-size: 28px;
					}
	
					.footer__item {
						& svg  {
							width: 30px;
							height: 30px;
						}
	
						.footer__link {
							font-size: 20px;
						}
					}
	
					.footer__social-media {
						margin-top: 30px;
					}
				}
			}

			&__description {
				font-size: 20px;
			}
	
			&__copyright {
				padding: 30px 0;
				&-text {
					font-size: 18px;
				}
			}
		}

		.footer__card {
			padding: 0 160px;

			&-card {
				width: 300px;
				height: 440px;
				padding: 20px;
	
				.footer__card-img {
					max-width: 90px;
					max-height: 90px;
				}
	
				.footer__card-name {
					font-size: 24px;
					margin-top: 10px;
				}
	
				.footer__card-position {
					font-size: 20px;
				}

	
	
				.footer__card-container {
					margin-top: 15px;
	
					&--info {
						margin-top: 25px;
					}
	
					.footer__card-box__description {
						font-size: 24px;
	
						&--info {
							margin-top: 30px;
						}
					}
				}
	
				.footer__card-box {
					.footer__card-img {
						width: 26px;
						height: 26px;
						transition: .5s;
	
						& path {
							transition: .5s;
						}
					}
	
	
					.footer__card-text {
						font-size: 20px;
					}
				}
	
				.footer__sociable {
					margin-top: 15px;
					column-gap: 15px;
				}
			}
		}
	}
}


@media screen and (max-width: 1700px) {
	.footer__wrapper  {
		.footer {
			padding: 320px 120px 0px;

			&__row {
				flex-wrap: wrap;
				column-gap: 80px;
				row-gap: 80px;

				.footer__info {
					.footer__logo {
						& img {
							margin-bottom: 20px;
							width: 158px;
						}
					}

					&-description {
						max-width: 270px;
						font-size: 18px;
					}
				}

				.footer__list {
					.footer__title {
						margin-bottom: 20px;
					}

					.footer__item {
						margin-bottom: 10px;
						& svg  {
							width: 24px;
							height: 24px;
						}

						.footer__link {
							font-size: 18px;
						}
					}

					.footer__social-media {
						margin-top: 20px;
					}
				}
			}

			&__description {
				font-size: 18px;
			}

			&__copyright {
				padding: 25px 0;
				&-text {
					font-size: 16px;
				}
			}
		}


		.footer__card {
			padding: 0 120px;
	
			position: absolute;
			transform: translateY(-50%);
			width: 100%;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			column-gap: 30px;
			row-gap: 40px;
			justify-items: center;


			&-card {
				width: 290px;
				height: 400px;
				padding: 15px;
	
				.footer__card-img {
					max-width: 80px;
					max-height: 80px;
				}
	
				.footer__card-name {
					font-size: 20px;
					margin-top: 10px;
				}
	
				.footer__card-position {
					font-size: 18px;
				}

				.footer__card-languages {
					& svg {
						width: 30px;
						height: 20px;
					}
				}
	
				.footer__card-container {
					margin-top: 20px;
	
					&--info {
						margin-top: 22px;
					}
	
					.footer__card-box__description {
						font-size: 20px;
					}
				}
	
				.footer__card-box {
					column-gap: 5px;
					.footer__card-img {
						min-width: 24px;
						min-height: 24px;
					}
	
					.footer__card-text {
						font-size: 18px;
					}
				}
			}
		}
	}
}


@media screen and (max-width: 1550px) {
	.footer__wrapper {
		.footer {
			padding: 290px 120px 0;
		}

		.footer__card {
			&-card {
				width: 250px;
				height: 390px;	
				
				justify-self: center;
				align-self: center;


				.footer__card-container {
					margin-top: 20px;
	
	
					.footer__card-box__description {
						margin-bottom: 10px;
	
						&--info {
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1370px)  {
	.footer__wrapper {
		.footer {
			padding: 260px 120px 0;
		}


		.footer__card {
			column-gap: 35px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			&-card {
				width: 210px;
				height: 330px;
				
				justify-self: center;
				align-self: center;

				.footer__card-img {
					min-width: 60px;
					min-height: 60px; 
					max-width: 60px;
					max-height: 60px;
				}
	

				.footer__card-name {
					font-size: 17px;
				}

				.footer__card-position {
					font-size: 14px;
				}


				.footer__card-languages {
					& svg {
						width: 24px;
						height: 24px;
					}
				}


				.footer__card-container {
					margin-top: 10px;
	
					&--info {
						margin-top: 18px;
					}
	
					.footer__card-box__description {
						margin-bottom: 10px;
	
						&--info {
							margin-top: 25px;
						}
					}
				}

				.footer__card-box {
					column-gap: 5px;

					.footer__card-img {
						min-width: 20px; 
						min-height: 20px; 
						width: 22px;
						height: 22px;
					}
					.footer__card-text {
						font-size: 14px;
					}
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.footer__wrapper {
		.footer {
			padding: 260px 80px 0;


			&__row {
				column-gap: 60px;

				.footer__logo {
                    margin-bottom: 20px;
                }

				.footer__list {
					.footer__title {
						margin-bottom: 20px;
						font-size: 28px;
					}


					.footer__item {
						.footer__link {
							font-size: 18px;
						}
					}
				}
			}

			&__description {
				font-size: 18px;
			}

		}

		.footer__card {
			padding: 0 60px;
		}
	}
}


@media screen and (max-width: 1076px) {
	.footer__wrapper {
		.footer {
			padding: 450px 80px 0;
		}

		.footer__card {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}


@media screen and (max-width: 800px) {
	.footer__wrapper {
		.footer {
			padding: 520px 30px 0;

			&__row {
				flex-direction: column;
				justify-content: center;
				align-items: center;


				.footer__info {
					width: 100%;

					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;

					.footer__logo {
						margin-bottom: 0px;
					}
					
					&-description {
						max-width: 70%;
					}
				}


				.footer__list {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					row-gap: 5px;

					.footer__item {
						svg {
							min-height: 24px;
							min-width: 24px;
						}
					}
				}
			}

			&__copyright {
				flex-direction: column;
				row-gap: 20px;
			}
		}

		.footer__card {
			grid-template-columns: repeat(1, 1fr);

			&-card {
				width: 290px;
				height: 380px;
				padding: 15px;
	
				.footer__card-img {
					min-width: 85px;
					min-height: 85px;
				}
	
				.footer__card-name {
					font-size: 20px;
					margin-top: 10px;
				}
	
				.footer__card-position {
					font-size: 20px;
				}

				.footer__card-languages {
					& svg {
						width: 30px;
						height: 20px;
					}
				}


	
				.footer__card-container {
					margin-top: 15px;
	
					&--info {
						margin-top: 22px;
					}
	
					.footer__card-box__description {
						font-size: 20px;
			
						&--info {
							margin-top: 50px;
						}
					}
				}
	
				.footer__card-box {
					.footer__card-img {
						width: 24px;
						height: 24px;
					}
	
					.footer__card-text {
						font-size: 18px;
					}
				}
			}
		}
	}
}


// @media screen and (max-width: 500px) {
// 	.footer__wrapper {
// 		.footer {
// 			padding: 800px 30px 0;
// 		}
// 	}
// }


@media screen and (max-width: 750px) {
	.footer__wrapper {
		.footer {
			padding: 940px 30px 0;
		}
	}
}


@media screen and (max-width: 500px) {
	.footer__wrapper {
		.footer {
			padding: 870px 30px 0;

			&__row {
				.footer__info {
					&-description {
						max-width: 100%;
					}
				}


				.footer__list {
					display: flex;
					flex-direction: column;
					align-items: center;
					row-gap: 5px;

					.footer__item  {
						column-gap: 5px;
					}

					.footer__social-media {
						margin-top: 15px;
					}
				}
			}


			&__copyright {
				&-producer {
					flex-direction: column;
					row-gap: 15px;
				}
			}
		}

		.footer__card {
			padding: 0 30px;
			grid-template-columns: repeat(1, 1fr);

			&-card {
				width: 260px;
				height: 350px;

				.footer__card-container {
					margin-top: 10px;
					.footer__card-box__description {
						&--info {
							margin-top: 50px;
						}
					}
				}

				.footer__card-position {
					font-size: 18px;
				}

				.footer__card-box {
					gap: 5px;

					.footer__card-img {
						width: 20px;
						height: 20px;
					}

					.footer__card-text {
						font-size: 16px;
					}
				}
			}
		}
	}
}