@import "./../../../main/style.scss";

.social-meadia {
    width: 60px;
    height: 60px;

    border-radius: 3px;

    @include display-center(center, center);
    cursor: pointer;
    transition: .5s;

    & svg {
        width: 80px;
        height: 80px;
    }

    &--white {
        background: $white;
        
        &:hover {
            background: $main;

            & svg path {
                fill: $white;
            }
        }
    }

    &--blue {
        background: $main;

        & svg path {
            fill: $white;
        }

        &:hover {
            &:hover {
                background: $dark;
            }
        }
    }

    &--mini {
        width: 50px;
        height: 50px;
    }



    &:hover {
        transition: .5s;
        
        & svg path {
            transition: .5s;
        }
    }
}


@media screen and (max-width: 2000px) {
    .social-meadia {
        width: 44px;
        height: 44px;

        &--mini {
            width: 40px;
            height: 40px;
        }
    }
}


@media screen and (max-width: 1700px) {
    .social-meadia {
        width: 40px;
        height: 40px;

        &--mini {
            width: 36px;
            height: 36px;
        }
    }
}


@media screen and (max-width: 1370px) {
    .social-meadia {
        // width: 46px;
        // height: 46px;

        &--mini {
            width: 36px;
            height: 36px;
        }
    }
}