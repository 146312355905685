@import "./../../main/style.scss";

.about-us {
    margin-bottom: 140px;
    padding: 140px 180px 0;

    &.animate {
        transition: 1s ease;

        .about-us__row {
            .about-us__content, .about-us__image {
                transform: translateX(0px);
                opacity: 1;
                transition: 1s;
            }
        }
    }

    &__row {
        @include display-center(space-between, center);
        column-gap: 30px;

        .about-us__content {
            opacity: 0;
			transform: translateX(-100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: .8;
			}

            .about-us__title {
                margin-bottom: 50px;
            }

            .about-us__text {
                margin-bottom: 30px;
                max-width: 900px;
                @include font($dark, 30px, 400, normal, 0);
            }

            .about-us__experience {
                margin-top: 15px;
                @include display-center(none, center);
                column-gap: 15px;

                & svg {
                    min-width: 64px;
                    min-height: 64px;
                }

                &-text {
                    @include font($dark, 32px, 700, normal, 0);
                }
            }
        }

        .about-us__image {
            width: 42%;
            opacity: 0;
			transform: translateX(100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: .8;
			}
        }
    }
}



@media screen and (max-width: 2000px) {
    .about-us {
        padding: 140px 160px 0;


        &__row {
            .about-us__content {
                .about-us__text {
                    margin-bottom: 30px;
                    max-width: 700px;
                    font-size: 24px;
                }

                .about-us__experience {
                    & svg {
                        min-width: 54px;
                        min-height: 54px;
                    }
    
                    &-text {
                        max-width: 780px;
                        font-size: 28px;
                    }
                }
            }

            .about-us__image {
                width: 46%;
            }
        }
    }
}


@media screen and (max-width: 1700px) {
    .about-us  {
        padding: 140px 120px 0;

        &__row {
            .about-us__content {
                .about-us__text {
                    margin-bottom: 20px;
                    max-width: 540px;
                    font-size: 18px;
                }
    
                .about-us__experience {
                    & svg {
                        min-width: 40px;
                        min-height: 40px;
                    }
    
                    &-text {
                        max-width: 540px;
                        font-size: 24px;
                    }
                }
            }

            .about-us__image {
                max-width: 42%;
                height: 550px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .about-us {
        padding: 0 60px;
        padding-top: 140px;

        &__row {
            .about-us__content {
                .about-us__title {
                    margin-bottom: 30px;
                }

                .about-us__text {
                    max-width: 440px;
                    font-size: 15px;
                }

                .about-us__experience {
                    column-gap: 10px;

                    & svg {
                        min-width: 34px;
                        min-height: 34px;
                    }

                    &-text {
                        max-width: 440px;
                        font-size: 20px;
                    }
                }
            }

            .about-us__image {
                max-width: 40%; 
                height: 400px;
            }
        }
    }
}



@media screen and (max-width: 800px) {
    .about-us {
        padding: 0 30px;
        padding-top: 140px;

        &__row {
            flex-direction: column;
            column-gap: 0px;
            row-gap: 50px;

    
            .about-us__content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .about-us__title {
                    text-align: center;
                }
    
                .about-us__text {
                    max-width: 85%;
                    text-align: center;
                    font-size: 18px;
                }
    
                .about-us__experience {
                    margin: 0 auto;
                    margin-bottom: 15px;

                    &-text {
                        & svg {
                            min-width: 30px;
                            min-height: 30px;
                        }

                        max-width: 460px;
                        text-align: center;
                        font-size: 18px;
                    }
                }
            }
    
            .about-us__image {
                width: auto;
                max-width: 75%;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .about-us {
        &__row {
            .about-us__content {
                max-width: 100%;

                .about-us__text {
                    font-size: 16px;
                }
            }

            .about-us__image {
                max-width: 100%;
                height: 70%;
            }
        }
    }
}