@import "./../../../main/style.scss";

.service__card {
    width: 420px;
    height: 340px;

    border-radius: 5px;
    background-color: $white;
    box-shadow: 0px 0px 4.5px 0px rgba(0, 108, 192, 0.65);
    @include display-center(center, center);
    flex-direction: column;

    justify-self: center;
    align-self: center;
    row-gap: 20px;

    &--man {
        row-gap: 20px;
    }

    &--poppy {
        row-gap: 15px;
    }

    &--pallet {
        row-gap: 55px;
    }

    &--sugar {
        row-gap: 50px;
    }

    &--snail {
        row-gap: 15px;
    }

    &-img {
        min-height: 140px;
        width: 190px;
    }

    &-description {
        text-align: center;
        max-width: 380px;

        text-align: center;
        @include font($dark, 26px, 400, normal, 0);
    }
}


@media screen and (max-width: 2000px) {
    .service__card {
        width: 450px;
        height: 366px;
    }
}

@media screen and (max-width: 1700px) {
    .service__card {
        width: 340px;
        height: 280px;

        
        &--man  img{
           max-width: 180px;
        }

        &--poppy {
            row-gap: 10px;

            & img {
                height: 150px;
                width: 200px;
            }
        }

        &--pallet {
            row-gap: 30px;
            }
    
        &--sugar {
            row-gap: 10px;
        }

        &-img {
            min-height: auto;
            max-width: 200px;
        }

        &-description {
            max-width: 280px;
            font-size: 20px;
        }
    }
}


@media screen and (max-width: 1200px) {
    .service__card {
        width: 328px;
        height: 270px;

        &--man {
            row-gap: 15px;
        }
    
        &--poppy {
            row-gap: 20px;
        }
    
        &--pallet {
            row-gap: 20px;
        }
    
        &--sugar {
            row-gap: 23px;
        }
    
        &--snail {
            row-gap: 50px;
        }

        &-img {
            max-height: 160px;   
            max-width: 220px;
        }

        &-description {
            max-width: 260px;
            font-size: 18px;
        }
    }
}


@media screen and (max-width: 800px) {
    .service__card {
        width: 80%;
        height: 400px;

        &-img {
            width: 80%;
            max-height: 50%;
        }
    }
}

@media screen and (max-width: 500px) {
    .service__card {
        width: 100%;
        height: 330px;

        &-img {
            width: auto;
            max-height: 65%;
        }

        &-description {
            max-width: calc(100% - 40px) ;
        }
    }
}