@import "./../../../main/style.scss";


.faq__card {
    border-radius: 5px;
    background: $white;
    box-shadow: 0px 0px 4.5px 0px rgba(51, 51, 51, 0.80);

    padding: 0 25px;
    width: 100%;
    border-radius: 5px;

    overflow: hidden;
    height: 120px;

    transition: height .6s; 

    li {
        margin-top: 25px;

        p {
            padding-bottom: 10px;
            margin-bottom: -25px;
            color: $main;
            font-size: 32px;
        }

        strong {
            // margin-right: 8px;
            font-weight: 400;
        }
    }

    &._open {
        transition: height .6s; 
    }


    &-question {
        width: 100%;
        height: 120px;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &-text {
            @include font($dark, 36px, 500, normal, 0); 
        }

        .faq__card-btn {
            min-width: 70px; 
            min-height: 70px; 
            background-color: $main;
            border-radius: 50%;
    
            @include display-center(center, center);
            cursor: pointer;
            transition: .4s;

            & svg {
                width: 70px;
                height: 70px;
            }

            &._active {
                transform: rotate(135deg);
            }
        }
    }

    &-dropdown-text {
        transition: height 0.6s; 

        // margin-bottom: 40px;
        @include font($dark, 28px, 400, normal, 0);
    }
}




@media screen and (max-width: 2000px) {
    .faq__card {

        &-question {
            &-text {
                font-size: 36px;
            }

            .faq__card-btn {
                min-width: 66px;
                min-height: 66px;
    
                & svg {
                    width: 66px;
                    height: 66px;
                }
            }
        }

        
        &-dropdown-text {
            font-size: 26px;
        }
    }
}


@media screen and (max-width: 1700px) {
    .faq__card {
        padding: 0 25px;

        li {
            p {
                font-size: 20px;
            }
        }
    
        &-question {
            width: 100%;
            
            &-text {
                font-size: 30px;
            }
    
            .faq__card-btn {
                min-width: 60px;
                min-height: 60px;
    
                & svg {
                    width: 52px;
                    height: 52px;
                }
            }
        }
    
        &-dropdown-text {
            font-size: 18px;
        }
    }
}


@media screen and (max-width: 1200px) {
    .faq__card {
        li {
            p {
                font-size: 20px;
            }
        }

        &-question {
            &-text {
                font-size: 26px;
            }
        }

        &-dropdown-text {
            font-size: 18px;
        }
    }
}


@media screen and (max-width: 800px) {
    .faq__card {
        padding: 0 20px;
        height: 100px;

        li {
            margin-top: 25px;
            
            p {
                font-size: 18px;
            }
        }
        &-question {
            margin-bottom: 10px;
            height: 100px;

            &-text {
                font-size: 20px;
                margin-right: 20px;
            }
        }

        &-dropdown-text {
            font-size: 16px;
        }
    }
}


@media screen and (max-width: 500px) {
    .faq__card {
        padding: 0 15px;

        &-question {
            column-gap: 10px;

            &-text {
                font-size: 14px;
                max-width: 85%;
                margin-right: 5px;
            }

            .faq__card-btn {
                min-width: 40px;
                min-height: 40px;

                & svg {
                    height: 35px;
                    width: 35px;
                }
    
                &._active {
                    transform: rotate(135deg);
                }
            }
        }
    }
}